<ng-container *ngIf="user$ | async as user">
  <eui-app
    [userInfos]="user?.firstName + ' ' + user?.lastName"
    [userSubInfos]="user?.role ? ('ENUM.ROLE.' + user.role | translate) : ''"
  >
    <eui-app-header>
      <eui-header>
        <eui-header-logo></eui-header-logo>

        <eui-header-app appName="SME Week"></eui-header-app>

        <eui-header-user-profile>
          <eui-user-profile-menu>
            <eui-user-profile-menu-item (click)="signOut()">
              <eui-icon iconClass="eui-icon-logout-thin"></eui-icon>
              {{ 'SIGN_OUT' | translate }}
            </eui-user-profile-menu-item>
          </eui-user-profile-menu>
        </eui-header-user-profile>
      </eui-header>
    </eui-app-header>

    <eui-app-toolbar *ngIf="user?.isActive">
      <eui-toolbar>
        <eui-toolbar-menu
          [items]="sidebarItems"
          [attr.data-cy]="'main-menu'"
        ></eui-toolbar-menu>
      </eui-toolbar>
    </eui-app-toolbar>
  </eui-app>
</ng-container>
