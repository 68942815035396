import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, EuiAppConfig, LocalStorageService } from '@eui/core';
import { Observable, switchMap, zip } from 'rxjs';
import { AuthenticationService } from './core/services/authentication.service';
import { LanguageService } from './core/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class AppStarterService {
  constructor(
    private languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    @Inject(CONFIG_TOKEN) private config: EuiAppConfig
  ) {}

  start(): Observable<any> {
    return zip(
      this.authenticationService.getUserAuthenticated$().pipe(
        switchMap(() => {
          return this.languageService.setLanguage(
            this.localStorageService.get('preferedLocale') ||
              this.config.global.i18n.i18nService.defaultLanguage
          );
        })
      )
    );
  }
}
