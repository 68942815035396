import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserHasRoleAndIsActiveGuard } from './core/guards/user-has-role-and-is-active.guard';
import { UserIsECSGuard } from './core/guards/user-is-ecs.guard';
import { UserIsNotActiveGuard } from './core/guards/user-is-not-active.guard';

const routes: Routes = [
  { path: '', redirectTo: 'events-validation', pathMatch: 'full' },
  {
    path: 'request-access',
    canActivate: [UserIsNotActiveGuard],
    loadChildren: () =>
      import('./features/request-access/request-access.module').then(
        m => m.RequestAccessModule
      ),
  },
  {
    path: '',
    canActivate: [UserHasRoleAndIsActiveGuard],
    children: [
      {
        path: 'events-validation',
        loadChildren: () =>
          import('./features/events-validation/events-validation.module').then(
            m => m.EventsValidationModule
          ),
      },
      {
        path: 'national-coordinators-management',
        canActivate: [UserIsECSGuard],
        loadChildren: () =>
          import(
            './features/national-coordinators-management/national-coordinators-management.module'
          ).then(m => m.NationalCoordinatorsManagementModule),
      },
    ],
  },
  { path: '**', redirectTo: 'events-validation' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
