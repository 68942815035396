import { NgModule } from '@angular/core';
import { EuiAllModule } from '@eui/components';
import { UxAllModule } from '@eui/components/legacy';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [UxAllModule, EuiAllModule, TranslateModule],
  declarations: [],
  exports: [UxAllModule, EuiAllModule, TranslateModule],
})
export class SharedModule {}
