import { Injectable } from '@angular/core';
import { I18nService, LocaleService, LocalStorageService } from '@eui/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _languageSubject = new BehaviorSubject<string>(null);
  private _language$ = this._languageSubject.asObservable();

  constructor(
    private localStorageService: LocalStorageService,
    private localeService: LocaleService,
    private i18nService: I18nService
  ) {
    this.i18nService.onStateChange
      .pipe(
        map(v => v.activeLang),
        tap(v => {
          this.localStorageService.set('preferedLocale', v);
        }),
        switchMap(v => this.setLocale(v))
      )
      .subscribe();
  }

  getLanguage(): Observable<string> {
    return this._language$;
  }

  setLanguage(languageId: string): Observable<void> {
    return this.i18nService
      .init({ activeLang: languageId })
      .pipe(switchMap(() => this.setLocale(languageId)));
  }

  private setLocale(localeId: string): Observable<void> {
    return this.localeService.addLocale(localeId).pipe(
      tap(() => this._languageSubject.next(localeId)),
      switchMap(() => {
        return of(
          this.localeService.updateState({
            id: localeId,
          })
        );
      })
    );
  }
}
