import { Injectable } from '@angular/core';
import {
  ApproveAccessRequestCommand,
  RejectAccessRequestCommand,
  RequestAccessCommand,
  UpdateUserCommand,
  UserDetailDto,
} from '@dg-grow/common';
import { API } from 'aws-amplify';
import { plainToInstance } from 'class-transformer';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  getUsers(): Observable<UserDetailDto[]> {
    return from(API.get('Main', '/user', {})).pipe(
      map((users: UserDetailDto[]) =>
        users.map(u => plainToInstance(UserDetailDto, u))
      )
    );
  }

  getUserByLogin(login: string): Observable<UserDetailDto> {
    return from(
      API.get('Main', '/user/by-login', {
        queryStringParameters: {
          login,
        },
      })
    ).pipe(map(v => plainToInstance(UserDetailDto, v)));
  }

  updateUser(updateUserCommand: UpdateUserCommand): Observable<UserDetailDto> {
    return from(
      API.put('Main', '/user', {
        body: updateUserCommand,
      })
    ).pipe(map(v => plainToInstance(UserDetailDto, v)));
  }

  requestAccess(requestAccessCommand: RequestAccessCommand): Observable<void> {
    return from(
      API.post('Main', '/user/request-access', {
        body: requestAccessCommand,
      })
    );
  }

  approveAccessRequest(
    approveAccessRequestCommand: ApproveAccessRequestCommand
  ): Observable<void> {
    return from(
      API.post('Main', '/user/approve-access-request', {
        body: approveAccessRequestCommand,
      })
    );
  }

  rejectAccessRequest(
    rejectAccessRequestCommand: RejectAccessRequestCommand
  ): Observable<void> {
    return from(
      API.post('Main', '/user/reject-access-request', {
        body: rejectAccessRequestCommand,
      })
    );
  }
}
