import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
} from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UserIsNotActiveGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.check();
  }

  canActivateChild(): Observable<boolean> {
    return this.check();
  }

  canLoad(): Observable<boolean> {
    return this.check();
  }

  private check(): Observable<boolean> {
    return this.authenticationService.getUserAuthenticated$().pipe(
      map(u => !u.isActive),
      tap(userNotActive =>
        userNotActive ? true : this.router.navigateByUrl('')
      )
    );
  }
}
