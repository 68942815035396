export const environment = {
  production: true,
  apiUrl: 'https://api.test-bo.grow-sme-week.eu',
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_pelYvSZFX',
    userPoolClientId: '43nm25tr144rnroqav0oh5cj3i',
    userPoolDomain: 'test-grow-sme-week',
    redirectSignOut: 'https://ecas.acceptance.ec.europa.eu/cas/logout',
  },
  cookieStorage: {
    secure: true,
  },
  frontofficeUrl: 'https://test.sme-week.ec.europa.eu/home/view/',
};
