import { Injectable } from '@angular/core';
import { CognitoUser } from '@aws-amplify/auth';
import { UserDetailDto } from '@dg-grow/common';
import { Auth } from 'aws-amplify';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { UserService } from './user.service';

export interface EuiLoggedInUser {
  login: string;
  email: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _authenticatedUserSubject = new BehaviorSubject<UserDetailDto>(null);
  private _authenticatedUser$ = this._authenticatedUserSubject.asObservable();

  constructor(private userService: UserService) {}

  signOut(): void {
    this._authenticatedUserSubject.next(null);
    Auth.signOut();
  }

  refreshUserAuthenticated$(): Observable<UserDetailDto> {
    return this.getAuthenticatedUserOrRedirect$();
  }

  getUserAuthenticated$(): Observable<UserDetailDto> {
    if (this._authenticatedUserSubject.value) {
      return this._authenticatedUser$;
    } else {
      return this.getAuthenticatedUserOrRedirect$();
    }
  }

  private getAuthenticatedUserOrRedirect$(): Observable<UserDetailDto> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      catchError(err => {
        Auth.federatedSignIn();
        return throwError(() => err);
      }),
      map((u: CognitoUser) => {
        const user = {} as EuiLoggedInUser;
        u.getUserData((_, u) => {
          user.email = u.UserAttributes.find(ua => ua.Name === 'email')?.Value;
          user.firstName = u.UserAttributes.find(
            ua => ua.Name === 'given_name'
          )?.Value;
          user.lastName = u.UserAttributes.find(
            ua => ua.Name === 'family_name'
          )?.Value;
        });
        user.login = u.getUsername();
        return user;
      }),
      switchMap(u =>
        this.userService.updateUser(u).pipe(catchError(() => of(u)))
      ),
      switchMap(u =>
        this.userService.getUserByLogin(u.login).pipe(
          catchError(() => of(u)),
          map(u2 => ({ ...u, ...u2 }))
        )
      ),
      map((u: UserDetailDto) => {
        this._authenticatedUserSubject.next(u);
        return this._authenticatedUserSubject.value;
      })
    );
  }
}
