import { Auth } from 'aws-amplify';
import { environment } from './environments/environment';

const APP_CONFIG = {
  apiUrl: environment.apiUrl,
  cognito: {
    region: environment.cognito.region,
    userPoolId: environment.cognito.userPoolId,
    userPoolClientId: environment.cognito.userPoolClientId,
    userPoolDomain: environment.cognito.userPoolDomain,
    redirectSignOut: environment.cognito.redirectSignOut,
  },
};

export const awsconfig = {
  Auth: {
    region: APP_CONFIG.cognito.region,
    userPoolId: APP_CONFIG.cognito.userPoolId,
    userPoolWebClientId: APP_CONFIG.cognito.userPoolClientId,
    mandatorySignIn: false,
    oauth: {
      domain: `${APP_CONFIG.cognito.userPoolDomain}.auth.${APP_CONFIG.cognito.region}.amazoncognito.com`,
      clientId: APP_CONFIG.cognito.userPoolClientId,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.protocol + '//' + window.location.host,
      redirectSignOut: APP_CONFIG.cognito.redirectSignOut,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 365,
      secure: environment.cookieStorage.secure,
    },
  },
  API: {
    endpoints: [
      {
        name: 'Main',
        endpoint: APP_CONFIG.apiUrl,
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};
