import { LanguageService } from './core/services/language.service';
import { Component, OnInit } from '@angular/core';
import { Role, UserDetailDto } from '@dg-grow/common';
import { EuiMenuItem } from '@eui/components/eui-menu';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { AuthenticationService } from './core/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  user$: Observable<UserDetailDto>;

  sidebarItems: EuiMenuItem[] = [];
  pdfUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.user$ = this.authenticationService.getUserAuthenticated$().pipe(
      tap(u => {
        this.sidebarItems.push({
          label: this.translateService.instant('MENU.EVENTS_VALIDATION'),
          url: 'events-validation',
        });

        if (u?.role === Role.ECS) {
          this.sidebarItems.push({
            label: this.translateService.instant(
              'MENU.NATIONAL_COORDINATORS_MANAGEMENT'
            ),
            url: 'national-coordinators-management',
          });
        }

        this.sidebarItems.push({
          label: this.translateService.instant('MENU.USER_GUIDE'),
          urlExternal: this.pdfUrl,
        });

        this.sidebarItems.push({
          label: this.translateService.instant(
            'MENU.REPORTING_INFORMATION_SECURITY_INCIDENT'
          ),
          urlExternal:
            'https://single-market-economy.ec.europa.eu/reporting-information-security-incident_en',
        });
        if (this.sidebarItems && this.sidebarItems.length > 0) {
          this.getPdfLink(this.sidebarItems);
        }
      })
    );
  }

  getPdfLink(sidebarItems: any) {
    this.languageService.getLanguage().subscribe(lang => {
      if (lang && sidebarItems && sidebarItems.length > 0) {
        this.sidebarItems.forEach(i => {
          if (i.label === this.translateService.instant('MENU.USER_GUIDE')) {
            // temporary comment it out multi lang pdf because new manuals is only in English JIRA issue SWECFT-96
            // i.urlExternal = `assets/documents/User_s_Guide_for_NCs-${lang}.pdf`;
            i.urlExternal = `assets/documents/User_s_Guide_for_NCs-en.pdf`;
          }
        });
      }
    });
  }

  signOut() {
    this.authenticationService.signOut();
  }
}
